import { RelationshipFilter, Relationship } from '@/constants/relationshipTypes'
import { GenericTabs, INDICATOR_ACTIONS, INDICATOR_FAV, Schema } from '@/constants/app'
import { getDefaultTab, sortByTrueValue } from '@/helpers/app'
import { v4 as uuidv4 } from 'uuid'

const { ONE_TO_ONE, ONE_TO_MANY, MANY_TO_ONE, MANY_TO_MANY } = Relationship

const { X_TO_ONE, X_TO_MANY } = RelationshipFilter

/**
 * Checks if the relationship is being presented via the Custom Entity's Perspective
 * If not, this function will reverse the relationship.
 */
export const toPerspective = (key, relationships, filters = [], options = null) => {
  let value = relationships.map(relationship => {
    if (!relationship.key1) {
      return relationship
    }

    const ownPerpective = key === relationship.key1
    if (!ownPerpective) {
      /**
       * If the relationship is either "1to1" or "NtoM", no need to reverse it.
       * But if the relationship is either "1toN" or "Nto1", that's the time we reverse it.
       */
      let type = relationship.type
      if (type === ONE_TO_MANY) {
        type = MANY_TO_ONE
      } else if (type === MANY_TO_ONE) {
        type = ONE_TO_MANY
      }

      return {
        ...relationship,
        key1: relationship.key2,
        key2: relationship.key1,
        label1: relationship.label2,
        label2: relationship.label1,
        schema1: relationship.schema2,
        schema2: relationship.schema1,
        relationshipName1: relationship.relationshipName2,
        relationshipName2: relationship.relationshipName1,
        type,
      }
    }

    return relationship
  })

  const hideBuiltIn = options?.hideBuiltIn ?? true
  if (hideBuiltIn) {
    value = value.filter(({ builtIn }) => !builtIn)
  }

  const result = {
    value,
  }

  if (filters.includes(X_TO_MANY)) {
    result.xToN = value.filter(({ type }) => [ONE_TO_MANY, MANY_TO_MANY].includes(type))
  }

  if (filters.includes(X_TO_ONE)) {
    result.xTo1 = value.filter(({ type }) => [ONE_TO_ONE, MANY_TO_ONE].includes(type))
  }

  return result
}

export const formatColumnsForTable = (columns, options = {}) => {
  const result = {}
  const actionsColumn = {
    key: INDICATOR_ACTIONS,
    stickyColumn: true,
    class: 'text-center disabled',
  }

  columns = columns.map(item => ({
    ...item,
    sortable: options.sortable ?? true,
  }))

  columns = sortByTrueValue(columns, ['partOfName', 'searchable', 'favorite'])

  // If no title in entity, show uuid to make it possible to click and view
  if (columns.filter(item => item.partOfName).length === 0) {
    columns.unshift({
      id: uuidv4(),
      label: 'UUID',
      key: 'uuid_key',
      partOfName: true,
      searchable: true,
      favorite: true,
    })
  }

  if (options.displayableOnly) {
    result.displayableOnly = columns.filter(({ partOfName, searchable, favorite }) => partOfName || searchable || favorite)
    result.displayableOnly.push(actionsColumn)
  }

  columns.push(actionsColumn)

  result.value = columns

  return result
}

/**
 * Returns the router-link's `to`-attribute value depending on
 * `schemaName` -- whether it's a Custom Schema or one of the Standard ones.
 *
 * This function needs to be adjusted when a new route name format is done for Standard Entity View Routes
 */
export function formatRouterLink({ schemaName, tableKey, uuidKey }) {
  if (schemaName === Schema.CUSTOM) {
    return {
      name: 'custom-entity-data-view',
      params: {
        key: tableKey,
        id: uuidKey,
        tab: GenericTabs.OVERVIEW_360,
      },
    }
  }

  return {
    name: `${tableKey}-view`,
    params: {
      id: uuidKey,
      tab: getDefaultTab(tableKey),
    },
  }
}

export function getPermissionName(tableKey) {
  const permission = {
    contact: 'CRM',
    company: 'CRM',
    inquiry: 'Inquiry',
    todo: 'Todo',
    note: 'Note',
  }

  return permission[tableKey] || tableKey
}

export function getMainSourceTable(entity, fallback = null, key = 'mainSourceTable') {
  return entity[key].split('.')[1] || fallback
}

export const faveRelationshipKey = (schema, table) => `${INDICATOR_FAV}${schema}.${table}`

export const faveRelationshipUuid = (schema, table) => `${INDICATOR_FAV}${schema}.${table}-item-uuidKey`

export const faveRelationshipTable = (schema, table) => `${INDICATOR_FAV}${schema}.${table}-item-table`

export const faveRelationshipSchema = (schema, table) => `${INDICATOR_FAV}${schema}.${table}-item-schema`

export const relationshipsToColumns = (customEntity, relationships) =>
  toPerspective(customEntity.key, relationships, [X_TO_ONE]).xTo1.map(item => ({
    ...item,
    key: faveRelationshipKey(item.schema2, item.key2),
    label: item.relationshipName2 || item.label2,
  }))

export const columnsWithFavorites = (customEntity, columns) => {
  const favoriteRelationships = customEntity.relationships.filter(({ builtIn, favorite }) => !builtIn && favorite)

  return [
    ...columns.filter(({ key }) => !key.startsWith(INDICATOR_ACTIONS)),
    ...relationshipsToColumns(customEntity, favoriteRelationships),
    columns.find(({ key }) => key.startsWith(INDICATOR_ACTIONS)),
  ]
}

export const mapRowData = entityItem =>
  entityItem.map(({ favoriteConnections, values, connections }) => {
    const getRowName = (itemConnections, connType) => {
      if (!itemConnections) return null

      const companyConnection = itemConnections.find(connection => connection.tableKey === connType)
      if (!companyConnection || companyConnection.rows.length === 0) return null
      return companyConnection.rows[0]?.rowName
    }

    if (!!values && Object.hasOwn(values, 'selskapstilhørighet')) {
      values.selskapstilhørighet = getRowName(connections, 'company')
    }

    if (!!values && Object.hasOwn(values, 'kontakttilhørighet')) {
      values.kontakttilhørighet = getRowName(connections, 'contact')
    }

    const favorites = favoriteConnections.reduce((acc, { schemaKey, tableKey, rowName, uuidKey }) => {
      acc[faveRelationshipKey(schemaKey, tableKey)] = rowName
      acc[faveRelationshipUuid(schemaKey, tableKey)] = uuidKey
      acc[faveRelationshipTable(schemaKey, tableKey)] = tableKey
      acc[faveRelationshipSchema(schemaKey, tableKey)] = schemaKey
      return acc
    }, {})
    return { ...values, ...favorites }
  })
